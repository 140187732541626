import React from "react";
import socketio from "socket.io-client";

import { store } from "../store/store";
import { setMarketsSnapshot, updateMarket } from "../store/actions/market-prices";
import { setMainMarkets } from "../store/actions/main-markets";

// const SOCKET_URL = "http://localhost:8887";
//const SOCKET_URL = "https://chain.btcoley.com";
const SOCKET_URL = "https://ws.btcoley.com";
// const SOCKET_URL = "http://192.168.5.105:8887";

/**
 * ORDER TYPE
 */
// type Order = {
//   pair: string;
//   position: string;
//   type: string;
//   ordertype: string;
//   price: number;
//   price2: number;
//   leverage: number;
//   order: string;
//   close: string;
// }

// type Desc = {
//   pair: string;
//   position: string;
//   type: string;
//   ordertype: string;
//   price: number;
//   price2: number;
//   leverage: number;
//   order: string;
//   close: string;
// }

// type OrderObject = {
//   refid: string;
//   // This is the user ID
//   userref: integer;
//   status: string;
//   opentm: number;
//   starttm: number;
//   expiretm: number;
//   descr: Desc;
//   vol: number;
//   vol_exec: number;
//   cost: number;
//   fee: number;
//   avg_price: number;
//   stopprice: number;
//   limitprice: number;
//   misc: string;
//   oflags: string;
//   timeinforce: string;
//   cancel_reason: string;
//   ratecount: integer;

// }

// type Order = {
//   orderid: OrderObject
// }

export const socket = socketio.connect(SOCKET_URL, {});
// socket.on("connect_error", err => {
//   console.log(`connect_error due to ${err.message}`);
// });
// socket.on("connect", () => {
//   console.log(socket.id);
// });

socket.on("trade", d => {
  // Apply logic
});

socket.on("markets", d => {
  store.dispatch(updateMarket(d));
});
socket.on("marketsnap", data => {
  let markets = [];
  markets = Object.keys(data).reduce((result, curr) => {
    let market = data[curr];
    let sellingCurr = market.details.s;
    (result[sellingCurr] = result[sellingCurr] || []).push({ [data[curr].details.c]: data[curr] });
    return result;
  }, {});

  store.dispatch(setMarketsSnapshot(data));
  store.dispatch(setMainMarkets(Object.keys(markets)));
});
export const SocketContext = React.createContext();
