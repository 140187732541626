import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TextCleaner from "text-cleaner";
import {RiCloseFill} from "react-icons/ri";
import {FiAlertCircle} from "react-icons/fi";
import {cleanprice} from "../../components/ui/FormatMarketPrice";
import connect from "react-redux/es/connect/connect";
import cryptocurrencies from "cryptocurrencies"
import Icon from '../../components/ui/IconLoader'
// import {formatMoney2} from "../../helper";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Marquee from "react-fast-marquee";

function MarqueePage(props) {
    const [loaded, setLoaded] = useState(false);
    const active = props.active ? props.active : "TRY";
    const [marketItems, setMarketItems] = useState({});
    const [search, setSearch] = useState("");

    const [filterStatus,setFilterStatus] = useState(false);
    const [pricesData,setPricesData] = useState({});
    const [defaultPricesData,setSetDefaultPricesData] = useState({});

    const pricesDefault = useSelector(state => state.prices);
    const prices = props.prices;
    const mainMarkets = useSelector(state => state.markets);

    if(filterStatus===false && pricesData!==pricesDefault){
        setSetDefaultPricesData(pricesDefault);
        setPricesData(pricesDefault);
    }

    const setAppropriateState = React.useCallback(() => {

        let items = Object.keys(prices).reduce((result, curr) => {
            let market = prices[curr];
            let sellingCurr = market.details.s;
            (result[sellingCurr] = result[sellingCurr] || []).push({ [prices[curr].details.c]: prices[curr] });
            return result;
        }, {});

        setMarketItems(items);
    }, [marketItems]);

    useEffect(() => {
        if (!loaded && mainMarkets.length > 0) {
            setLoaded(true);
            setAppropriateState();
            // console.log(props.theme);
        }
    }, [loaded, prices, mainMarkets]);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>
            <div id={'markettable'} className={props.theme}>

                <div className="flex flex-nowrap flex-col px-0 text-2xs">
                        {/*<Carousel*/}
                        {/*    swipeable={false}*/}
                        {/*    draggable={false}*/}
                        {/*    showDots={true}*/}
                        {/*    responsive={responsive}*/}
                        {/*    ssr={true} // means to render carousel on server-side.*/}
                        {/*    infinite={true}*/}
                        {/*    autoPlay={true}*/}
                        {/*    autoPlaySpeed={1000}*/}
                        {/*    keyBoardControl={true}*/}
                        {/*    customTransition="all .5"*/}
                        {/*    transitionDuration={500}*/}
                        {/*    containerClass="carousel-container"*/}
                        {/*    removeArrowOnDeviceType={["tablet", "mobile"]}*/}
                        {/*    deviceType={855 > 850 ? 'desktop' : 'mobile'}*/}
                        {/*    dotListClass="custom-dot-list-style"*/}
                        {/*    itemClass="carousel-item-padding-40-px"*/}
                        {/*>*/}
                        <Marquee
                            pauseOnHover={true}
                            speed={30}
                            gradient={false}
                        >
                            <div id="marquee" className={`market-items flex flex-nowrap flex-row w-screen gap-3 py-1 px-2 h-screen ${props.theme==='dark' ? 'bg-gray-800' : 'bg-gray-200'}`}>
                        {Object.keys(pricesData).map((key, index) => {
                            const code = key.replace("BTC", "XBT");
                            const coincode = key.split('/')[0].replaceAll('XBT','BTC');
                            const sell_coincode = key.split('/')[1].replaceAll('XBT','BTC');

                            const item = Object(prices[code]);

                            const itemPrice = item.prices || 0;
                            const itemDetails = item.details;

                            let direction = "up";
                            let changePercentage = 0;
                            if (itemPrice !== undefined) {
                                direction = Number(itemPrice.low) || 0 / Number(itemPrice.high) > 0 ? "up" : "down";
                                changePercentage = (Number(itemPrice.low) / Number(itemPrice.high)).toFixed(2);
                            }

                            if (!isNaN(changePercentage) && (itemDetails.l === active || itemDetails.b === active || itemDetails.s === active || active==="Hepsi") ) {

                                let formatprice;
                                if(code===props.sellorder?.value?.symbol){
                                    // formatprice = cleanprice(props.sellorder?.value?.px,itemDetails.display_decimals);
                                    formatprice = cleanprice(props.sellorder?.value?.px,2);
                                }else{
                                    formatprice = cleanprice((itemPrice && Object(props.prices)[code].prices.ask) || 0, 2);
                                    // formatprice = cleanprice((itemPrice && Object(props.prices)[code].prices.ask) || 0, itemDetails.display_decimals);
                                }

                                if(code!==props.sellorder?.value?.symbol){
                                    return (
                                        <a
                                            key={index}
                                            href={`https://pro.btcoley.com/market/${coincode}_${sell_coincode}`}
                                            target="_blank"
                                            className={`md:basis-[calc(100%/6)] md:min-w-[calc(100%/6)] sm:basis-[calc(100%/3)] sm:min-w-[calc(100%/3)] basis-[calc(100%/2)] min-w-[calc(100%/2)] first:mt-1 flex-1 items-center market-item px-2 cursor-pointer flex flex-row justify-between rounded ${props.theme==='dark' ? `even:bg-gray-950 odd:bg-gray-900 hover:bg-gray-800 text-gray-100` : `text-gray-900 even:bg-gray-100 odd:bg-gray-200 hover:bg-blue-200`} transition-all ease-in-out duration-300 py-1 text-md scroll-items`}>
                                            <div className="basis-1/2 flex gap-2 items-center ellipsis px-3">
                                                <Icon im={coincode} size={23} />
                                                <div className={'flex flex-col gap-0'}>
                                                    <span className={'pr-3'}>{coincode.replaceAll('XBT','BTC')}</span>
                                                    <span className={'ellipsis text-xs hidden md:inline-flex opacity-70'}>{Object(cryptocurrencies)[coincode] ? Object(cryptocurrencies)[coincode] : coincode}</span>
                                                </div>
                                            </div>
                                            <div className="basis-1/2 flex flex-nowrap flex-col justify-end items-end gap-1">
                                                <p className={'ori-price basis-1/4 grow md:grow-0 text-center whitespace-nowrap font-medium'}>{formatprice.ori}<span className={'sub-price'}>{formatprice.sub} {sell_coincode}</span></p>
                                                <div className={`text-right`}>
                                                    <span className={`text-white p-1 rounded ${direction === "up" ? "bg-up" : "bg-down"}`}>%{(changePercentage && !isNaN(changePercentage)) ? changePercentage : 0}</span>
                                                </div>
                                            </div>
                                        </a>
                                    );
                                }else{return '';}
                            }
                        })}

                        {(Object.values(pricesData).length===0) && (
                            <div className={`w-full p-4 text-base select-none flex gap-2 ${props.theme==='dark' ? 'bg-gray-800/80' : 'bg-gray-200'} text-gray-400 rounded mt-2 justify-center items-center`}>
                                <FiAlertCircle className={'text-xl'} />
                                <span>Sonuç yok</span>
                            </div>
                        )}
                            </div>
                        </Marquee>
                        {/*</Carousel>*/}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        ui: state.ui,
        user: state.user,
        balances: state.balances,
        prices: state.prices
    };
};
export default connect(mapStateToProps)(MarqueePage);