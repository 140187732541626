import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/number/is-nan';
import React from "react";
import ReactDOM from "react-dom";
// import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import Markets from "./pages/widgets/Markets";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { SocketContext, socket } from "./context/socket";
import jobs from "./api/jobs";
import { BrowserRouter } from "react-router-dom";
import RouterPage from "./Router";
import "./style.css";

new jobs();
// const widget = document.querySelector(".btcoley-widget");
    // ReactDOM.render(
    //     <Provider store={store}>
    //         <SocketContext.Provider value={socket}>
    //             <BtcOleyWidget widget={widget} />
    //         </SocketContext.Provider>
    //     </Provider>,
    //     widget
    // );

const widgets = document.querySelectorAll(".btcoley-widget");
widgets.forEach(widget => {
    ReactDOM.render(
        <Provider store={store}>
            <SocketContext.Provider value={socket}>
                <BrowserRouter>
                    <RouterPage widget={widget} />
                </BrowserRouter>
            </SocketContext.Provider>
        </Provider>,
        widget
    );
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
