import { createStore, combineReducers } from "redux";
import user from "./reducers/userReducer";
import ui from "./reducers/uiReducer";
import buysell from "./reducers/buy-sell-reducer";
import buyOrder from "./reducers/buy-price";
import sellOrder from "./reducers/sell-price";
import balances from "./reducers/userBalances";
import prices from "./reducers/market-prices";
import markets from "./reducers/main-markets";
import theme from "./reducers/theme";
import selectorderprice from "./reducers/selectOrderPrice";
import theme_schema from "./reducers/theme_schema";

const rootReducer = combineReducers({
  user: user,
  ui: ui,
  buysell: buysell,
  type: buysell,
  buyorder: buyOrder,
  sellorder: sellOrder,
  balances: balances,
  prices: prices,
  theme: theme,
  select_order_price: selectorderprice,
  theme_schema: theme_schema,
  markets: markets
});

// const persistConfig = {
//   key: "root",
//   blacklist: ["ui", "buysell", "prices", "markets"]
// };

// const persistedReducer = persistReducer(persistConfig, );
// store.subscribe(() => {});
export const store = createStore(
    rootReducer
);

// export const persistor = persistStore(store);

store.subscribe(() => {});
