const axios = require("axios");
const APIURL = "https://beta.api.btcoley.com/api";
//const APIURL = "https://panel.btclever.com/api/v1/";
// const APIURL = "http://localhost:8000/api/v1/";

const token = localStorage.getItem("token");

axios.defaults.baseURL = APIURL;
axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

const api = {
    makeRequest: function (method, url, data = {}, params = {}) {
        return axios.request({
            method,
            url,
            data,
            params,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        });
    },

    get: function (url, queryParams = {}) {
        return this.makeRequest('get', url, {}, queryParams);
    },

    post: function (url, data = {}) {
        return this.makeRequest('post', url, data);
    },

    delete: function (url, data = {}) {
        return this.makeRequest('delete', url, data);
    }
};

export { api };
