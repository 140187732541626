export const UPDATE_USER_BALANCES = "UPDATE_USER_BALANCES";

export function updateBalances(balances) {
  return {
    type: UPDATE_USER_BALANCES,
    payload: {
      balances: balances
    }
  };
}
