import { UPDATE_THEME } from "../actions/theme";

const initialState = {theme: localStorage.getItem('color-theme')===null ? "light" : localStorage.getItem('color-theme')};
export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_THEME:
            return (state = action.payload);
        default:
            return state;
    }
}
