import {useParams} from "react-router-dom";
import React from "react";
import Marquee from "./widgets/Marquee";
import Markets from "./widgets/Markets";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

function Page(props){
    let params = props.params;
    let theme = params.theme ?? 'light';
    switch (props.page){
        case 'marquee':
            return <Marquee {...props} params={params} theme={theme} />
        case 'market':
            return <Markets {...props} params={params} theme={theme} />
        default: return <Markets {...this.props} {...this.state} params={params} theme={theme} />
    }
}

export default withParams(Page);