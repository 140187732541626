import { UPDATE_USER_BALANCES } from "../actions/balances-actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({});
export default function (state = initialState, { type, payload }) {
  let balances = {};
  switch (type) {
    case UPDATE_USER_BALANCES:
      balances = payload.balances;
      break;
    default:
      balances = state;
      break;
  }
  return balances;
}
