import { SELL_ORDER } from "../actions/sell-price";

export default function (state = {}, action) {
  switch (action.type) {
    case SELL_ORDER:
      return (state = { value: action.payload, type: "buy" });
    default:
      return state;
  }
}
