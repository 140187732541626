import { formatMoney2 } from "../../helper";
import TextCleaner from "text-cleaner";

const tofix = (text) => {
    let lengt = String(text).length;
    //console.log(lengt);
    if(lengt<=14){
        let newtext = text+'0';
        return tofix(newtext);
    }else{
        if(lengt>14){
            return String(text).substr(0,14)
        }else{
            return text;
        }
    }
}

const cleanprice = (item,decimal=6, ret = 'array') => {
    try{
        // console.log(item);
        if(isNaN(item)){
            item = "0.0";
        }
        let nitem = TextCleaner(item).valueOf();

        let text;
        let append;

        text = formatMoney2(nitem.split('.')[0],decimal);
        append = ","+nitem.split('.')[1].substr(0,decimal);



        let ob      = {};
        ob['ori']   = text;
        ob['sub']   = append;
        ob['full']  = item;

        if(ret==='array'){
            return ob;
        }else{
            return text+append;
        }
    } catch (e){
        let ob = {};
        ob['ori'] = item;
        ob['sub'] = "";
        ob['full'] = "";
    }


}

export {
    tofix,
    cleanprice
}