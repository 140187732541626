import {getMyOrders, getOrders} from "./operational-api";

import { store } from "./../store/store";
import { updateUI } from "../store/actions/ui-actions";

class jobs {
  current_currency = localStorage.getItem("current_currency") ? localStorage.getItem("current_currency").replace('BTC','XBT') : "XBT/TRY";
  current_currency_label = localStorage.getItem("current_currency_label") ? localStorage.getItem("current_currency_label").replace('BTC','XBT') : "BTC/TRY";
  current_buying_currency = localStorage.getItem("current_buying_currency") ? localStorage.getItem("current_buying_currency").replace('BTC','XBT') : "BTC";
  current_selling_currency = localStorage.getItem("current_selling_currency") ? localStorage.getItem("current_selling_currency").replace('BTC','XBT') : "TRY";
  currentStorage = null;
  storage = null;
  orderIntervalHandle = null;

  constructor(){
    store.dispatch(
      updateUI({
        current_currency: this.current_currency,
        current_currency_label: this.current_currency_label,
        current_buying_currency: this.current_buying_currency,
        current_selling_currency: this.current_selling_currency
      })
    );
    store.subscribe(this.handleStoreChange);
    // this.statistics();
  }

  // async statistics() {
  //   try {
  //     let a = await getStatistics();
  //     store.dispatch(updateUI({ statistics: a.data }));
  //   } catch (e) {}
  //   setInterval(async () => {
  //     try {
  //       let a = await getStatistics();
  //       store.dispatch(updateUI({ statistics: a.data }));
  //     } catch (e) {}
  //   }, 5000);
  // }

  handleStoreChange = () => {
    this.storage = store.getState();
    let previousValue = this.currentStorage;
    this.currentStorage = store.getState().ui.current_currency;

    if (previousValue !== this.currentStorage) {
      if (this.orderIntervalHandle) {
        clearInterval(this.orderIntervalHandle);
      }
      this.orders();
      this.orderIntervalHandle = this.orders;
      //this.orderIntervalHandle = setInterval(this.orders, 5000);
    }
  };

  orders = async () => {
    let marketOrdersSell = [];
    let marketOrdersBuy = [];
    let marketLastOrders = [];
    let check = null;
    if (this.storage.user.isLogin) {
      check = await getMyOrders(this.storage.ui.current_currency, this.storage.user.token);
      let activeOrders = [];
      let completedOrders = [];
      let cancelOrders = [];
      const data = check.data.my_orders ?? [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].status===1) {
          completedOrders.push(data[i]);
        } else {
          activeOrders.push(data[i]);
          // if (data[i].status===2) {
          //   cancelOrders.push(data[i]);
          // } else {
          //   activeOrders.push(data[i]);
          // }
        }
      }
      store.dispatch(
        updateUI({
          completedOrders: completedOrders,
          activeOrders: activeOrders,
          cancelOrders: cancelOrders
        })
      );
    } else {
      check = await getOrders(this.storage.ui.current_currency);
    }
    if (check.data.orders) {
      const dataOrders = check.data.orders;
      for (let i = 0; i < dataOrders.length; i++) {
        if (dataOrders[i].type === "buy") {
          marketOrdersBuy.push(dataOrders[i]);
        } else {
          marketOrdersSell.push(dataOrders[i]);
        }
      }
    }
    if (check.data.last_orders) {
      const last_orders = check.data.last_orders;
      for (let i = 0; i < last_orders.length; i++) {
        if (last_orders[i].type === "buy") {
          marketLastOrders.push(last_orders[i]);
        } else {
          marketLastOrders.push(last_orders[i]);
        }
      }
    }
    store.dispatch(
      updateUI({
        marketOrdersSell: marketOrdersSell,
        marketOrdersBuy: marketOrdersBuy,
        marketLastOrders: marketLastOrders
      })
    );
  };
}

export default jobs;
