import { BUY_ORDER } from "../actions/buy-price";

export default function (state = {}, action) {
  switch (action.type) {
    case BUY_ORDER:
      return (state = { value: action.payload, type: "buy" });
    default:
      return state;
  }
}
