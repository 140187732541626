import { UPDATE_SELECTORDERPRICE } from "../actions/selectOrderPrice";

const initialState = {price:'', status: 'completed'};
export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_SELECTORDERPRICE:
            return (state = action.payload);
        default:
            return state;
    }
}
