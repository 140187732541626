export const UPDATE = "UPDATE";
export const SNAP = "SNAP";

export function setMarketsSnapshot(marketsSnapshot) {
  return {
    type: SNAP,
    payload: marketsSnapshot
  };
}

export function updateMarket(market) {
  return {
    type: UPDATE,
    payload: market
  };
}
