export const BUY_PROCESS = 'BUY_PROCESS';
export const SELL_PROCESS = 'SELL_PROCESS';

export function buyProcess(price) {
    return {
        type: BUY_PROCESS,
        payload: price
    }
}

export function sellProcess(price) {
    return {
        type: SELL_PROCESS,
        payload: price,
    }
}