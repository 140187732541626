import React from "react"
import {Routes, Route} from "react-router-dom";
import Markets from "./pages/widgets/Markets";
import WithParams from "./pages/WithParams"

export default class RouterPage extends React.Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Routes className={'router'}>
                <Route path="/">

                    <Route path="marquee" element={<WithParams page={'marquee'} {...this.props} {...this.state} />} />
                    <Route path="marquee/:theme" element={<WithParams page={'marquee'} {...this.props} {...this.state} />} />

                    <Route path="market" element={<WithParams page={'market'} {...this.props} {...this.state} />} />
                    <Route path="market/:theme" element={<WithParams page={'market'} {...this.props} {...this.state} />} />

                    <Route path="/" element={<Markets page={'/'} {...this.props} {...this.state} theme={'light'} />} />
                </Route>

                <Route path="*"  element={<div className={'w-screen h-screen absolute flex flex-1 items-center justify-center text-4xl'}><span>404</span></div>} />
            </Routes>
        )
    }
}

// export default withParams(RouterPage);