import {UPDATE_UI} from '../actions/ui-actions';
import Immutable from 'seamless-immutable';

const initialState = Immutable({});
export default function (state = initialState, {type, payload}) {
    var ui = {};
    switch (type) {
        case UPDATE_UI:
            ui = state.merge(payload.ui);
            break;


        default:
            ui = state;
            break;
    }
    return ui;
}
