import { UPDATE_USER } from "../actions/user-actions";
import Immutable from "seamless-immutable";

const initialState = Immutable({});
export default function (state = initialState, { type, payload }) {
  let user = {};
  switch (type) {
    case UPDATE_USER:
      user = { ...user, ...payload.user };
      break;
    default:
      user = state;
      break;
  }
  return user;
}
