import { UPDATE_MARKETS } from "../actions/main-markets";

export default function (state = [], action) {
  switch (action.type) {
    case UPDATE_MARKETS: {
      const { payload } = action;

      return payload;
    }
    default:
      return state;
  }
}
