export const UPDATE_UI = 'UPDATE_UI';

export function updateUI(data) {
    return {
        type: UPDATE_UI,
        payload: {
            ui: data
        }
    }
}
