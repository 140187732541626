import { BUY_PROCESS, SELL_PROCESS } from '../actions/buy-sell-actions';

export default function (state = {}, action) {
    switch (action.type) {
        case BUY_PROCESS:
            return state = { value: action.payload, type: 'buy' };

        case SELL_PROCESS:
            return state = { value: action.payload, type: 'sell' };

        default:
            return state;
    }
}