// import {Img} from "react-image";
// import {BsCoin} from "react-icons/bs";
import React from "react";
import TextCleaner from "text-cleaner";


const cleanCase = (text,uppercase=true) => {
    const a = TextCleaner(text).removeApostrophes().valueOf();

    if(uppercase===true){
        const clean = TextCleaner(a).toUpperCase().valueOf();
        return clean;
    }else{
        const clean = TextCleaner(a).toLowerCase().valueOf();
        return clean;
    }
}
const iconLoader = (props) => {
    let im = props.im ? props.im : 'try';
    let size = props.size ? props.size : 32;
    let reim = cleanCase(im,false).indexOf('.') !== -1 ? cleanCase(im).split('.')[0].toLowerCase().replace("xbt","btc") : cleanCase(im).toLowerCase().replace("xbt","btc");
    try{
        // console.log(reim);
        let icon = `https://www.gate.io/images/coin_icon/64/${reim}.png`;
        // let icon = require(`cryptocurrency-icons/svg/icon/${reim}.svg`);
        return (
            <img src={icon} width={size} />
        )
    } catch (e){
        if(reim==="try"){
            return <img src={`/assets/images/try.svg`} width={size}/>
            //return <BsCoin className={"text-4xl"}/>
        }else{
            let icon = `https://www.gate.io/images/coin_icon/64/${reim}.png`;
            //return <img src={gate} width={32} />
            return <img src={icon} width={size} />

        }

        //return <BsCoin className={"text-4xl"}/>
    }
    return '';
    // return <BsCoin className={"text-4xl"} width={size} height={size}/>;
}

export default iconLoader;