import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TextCleaner from "text-cleaner";
import {RiCloseFill} from "react-icons/ri";
import {FiAlertCircle} from "react-icons/fi";
import {cleanprice} from "./ui/FormatMarketPrice";
import connect from "react-redux/es/connect/connect";
import cryptocurrencies from "cryptocurrencies"
import Icon from './ui/IconLoader'
import {formatMoney2} from "../helper";

function MarketTable(props) {
  const [loaded, setLoaded] = useState(false);
  const active = props.active ? props.active : "TRY";
  const [marketItems, setMarketItems] = useState({});
  const [search, setSearch] = useState("");

  const [filterStatus,setFilterStatus] = useState(false);
  const [pricesData,setPricesData] = useState({});
  const [defaultPricesData,setSetDefaultPricesData] = useState({});

  const pricesDefault = useSelector(state => state.prices);
  const prices = props.prices;
  const mainMarkets = useSelector(state => state.markets);

  if(filterStatus===false && pricesData!==pricesDefault){
    setSetDefaultPricesData(pricesDefault);
    setPricesData(pricesDefault);
  }
  const cleanCase = text => {
    const a = TextCleaner(text).removeChars().valueOf();
    const clean = TextCleaner(a).toUpperCase().valueOf();

    return clean;
  }
  const searchHandle = (query) => {
    {defaultPricesData &&
    setFilterStatus(true);
      setSearch((query.target.value).toUpperCase());
      let searchQuery = cleanCase(search);
      let result = Object.values(defaultPricesData).filter((item) => {
        if (cleanCase(item.details.c).indexOf(searchQuery) !== -1) {
          return true;
        }
        if (cleanCase(item.details.c).indexOf(searchQuery.replace('BTC','XBT')) !== -1) {
          return true;
        }
        return false;
      });
      const obj = new Object();
      result.map((key) => {
        obj[key.details.c] = key;
      });
      setPricesData(obj);
    }
  }
  const resetMarketSearch = () => {
    setSearch("");
    setPricesData(defaultPricesData);
  }

  const setAppropriateState = React.useCallback(() => {

    let items = Object.keys(prices).reduce((result, curr) => {
      let market = prices[curr];
      let sellingCurr = market.details.s;
      (result[sellingCurr] = result[sellingCurr] || []).push({ [prices[curr].details.c]: prices[curr] });
      return result;
    }, {});

    setMarketItems(items);
  }, [marketItems]);

  useEffect(() => {
    if (!loaded && mainMarkets.length > 0) {
      setLoaded(true);
      setAppropriateState();
      // console.log(props.theme);
    }
  }, [loaded, prices, mainMarkets]);
  return (
      <>
        <div id={'markettable'} className={props.theme}>

          <div className={` ${props.theme==='dark' ? 'bg-gray-950' : 'bg-gray-100'} sticky top-0 z-50 shadow-lg`}>
            {/*<h3 className={`w-full py-0.5 px-3 font-medium text-xl ${props.theme==='dark' ? 'bg-gray-850 text-gray-100' : 'bg-blue-600 text-gray-100'} text-white z-10`}>PİYASALAR</h3>*/}
            {props.search==="true" && <div className="buysellinp-wrapper my-1">
              <div className="buysellinp-group group">
                <input
                    type="text"
                    value={search}
                    onChange={searchHandle}
                    onKeyPress={searchHandle}
                    onKeyDown={searchHandle}
                    onKeyUp={searchHandle}
                    id={"marketsearch"}
                    autoComplete={'off'}
                    className={`buysellinp !text-left`}
                    // readOnly={this.props.priceReadOnly}
                    placeholder={"Aramaya başla.."}
                />
                <div className="buysellinp-right">
                  <button href="#" onClick={resetMarketSearch} uk-tooltip="aramayı temizle">
                    <RiCloseFill className={'text-2xl'} />
                  </button>
                </div>
              </div>
            </div>}
          </div>

          <div className="flex flex-nowrap flex-col px-0 text-2xs">
            <div className={`market-items flex flex-nowrap flex-col gap-1 h-full --h-[calc(1010px)] --max-h-[calc(1010px)] overflow-y-auto scrollbar-st ${props.theme==='dark' ? 'bg-gray-800 text-gray-100' : 'bg-gray-200 text-gray-900'}`}>
              <div className={`sticky top-0 z-50 px-1 market-item-head flex flex-nowrap justify-between flex-1 w-full text-center py-2 ${props.theme==='dark' ? 'bg-gray-800 text-gray-100' : 'bg-gray-200 text-gray-900'}  text-2xs`}>
                <div className="basis-1/4 pl-4 text-left">İşlem Çifti</div>
                <div className="basis-1/4 grow text-center">Son Fiyat</div>
                <div className="basis-1/4 md:inline-flex hidden text-center">Hacim</div>
                <div className="basis-1/4 pr-2 text-right">Değişim</div>
              </div>


              {Object.keys(pricesData).map((key, index) => {
                const code = key.replace("BTC", "XBT");
                const coincode = key.split('/')[0].replaceAll('XBT','BTC');
                const sell_coincode = key.split('/')[1].replaceAll('XBT','BTC');

                const item = Object(prices[code]);

                const itemPrice = item.prices || 0;
                const itemDetails = item.details;

                let direction = "up";
                let changePercentage = 0;
                if (itemPrice !== undefined) {
                  direction = Number(itemPrice.low) || 0 / Number(itemPrice.high) > 0 ? "up" : "down";
                  changePercentage = (Number(itemPrice.low) / Number(itemPrice.high)).toFixed(2);
                }

                if (!isNaN(changePercentage) && (itemDetails.l === active || itemDetails.b === active || itemDetails.s === active || active==="Hepsi") ) {

                  let formatprice;
                  if(code===props.sellorder?.value?.symbol){
                    formatprice = cleanprice(props.sellorder?.value?.px,itemDetails.display_decimals);
                  }else{
                    formatprice = cleanprice((itemPrice && Object(props.prices)[code].prices.ask) || 0, itemDetails.display_decimals);
                  }

                  if(code!==props.sellorder?.value?.symbol){
                    return (
                        <a
                            key={index}
                            href={`https://pro.btcoley.com/market/${coincode}_${sell_coincode}`}
                            target="_blank"
                            className={`first:mt-2 flex-1 items-center market-item px-2 cursor-pointer flex flex-row justify-between py-1 rounded ${props.theme==='dark' ? `even:bg-gray-950 odd:bg-gray-900 hover:bg-gray-800 text-gray-100` : `text-gray-900 even:bg-gray-100 odd:bg-gray-200 hover:bg-blue-200`} transition-all ease-in-out duration-300 py-4 text-base`}>
                          <div className="basis-1/4 flex gap-2 items-center ellipsis">
                            <Icon im={coincode} />
                              <span className={'pr-3'}>{coincode}</span>
                              <span className={'ellipsis text-xs hidden md:inline-flex'}>{Object(cryptocurrencies)[coincode] ? Object(cryptocurrencies)[coincode] : coincode}</span>
                          </div>
                          <p className={'ori-price basis-1/4 grow md:grow-0 text-center'}>{formatprice.ori}<span className={'sub-price'}>{formatprice.sub} {sell_coincode}</span></p>
                          <div className="basis-1/4 text-center hidden md:inline-flex justify-center">{formatMoney2(parseFloat(itemPrice.volume ?? 0).toFixed(2 ?? itemDetails.display_decimals))}</div>
                          <span className={`basis-1/4 text-right`}>
                            <span className={`text-white p-1 rounded ${direction === "up" ? "bg-up" : "bg-down"}`}>%{(changePercentage && !isNaN(changePercentage)) ? changePercentage : 0}</span>

                          </span>
                        </a>
                    );
                  }else{return '';}
                }
              })}

              {(Object.values(pricesData).length===0) && (
                  <div className={`w-full p-4 text-base select-none flex gap-2 ${props.theme==='dark' ? 'bg-gray-800/80' : 'bg-gray-200'} text-gray-400 rounded mt-2 justify-center items-center`}>
                    <FiAlertCircle className={'text-xl'} />
                    <span>Sonuç yok</span>
                  </div>
              )}
            </div>
          </div>
        </div>
      </>
  );
}

const mapStateToProps = state => {
  return {
    ui: state.ui,
    user: state.user,
    balances: state.balances,
    prices: state.prices
  };
};
export default connect(mapStateToProps)(MarketTable);