import { UPDATE_THEME_SCHEMA } from "../actions/theme_schema";

const initialState = "default";
export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_THEME_SCHEMA:
            return (state = action.payload);
        default:
            return state;
    }
}
