import { UPDATE, SNAP } from "../actions/market-prices";

export default function (state = {}, action) {
  switch (action.type) {
    case SNAP: {
      const { payload } = action;

      return { ...payload };
    }
    case UPDATE: {
      const { payload } = action;
      return { ...state, ...payload };
    }
    default:
      return state;
  }
}
