import { api } from "./api";
import * as qs from "querystring";
const axios = require("axios");
//const APIURL = "http://api.btcoley.test/api";
const APIURL = "https://app.btcoley.com/api";
//const APIURL = "https://beta.api.btcoley.com/api";
//const APIURL = "https://panel.btclever.com/api/v1";
// const APIURL = "http://localhost:8000/api/v1";

async function login(email, password, country, phone, tckn, type) {
  let result = null;
  let data;
  if(type==="email"){
      data = {
          type:type,
          email:email,
          password
      }
  }
  if(type==="phone"){
      data = {
          type:type,
          country: country,
          phone:phone,
          password
      }
  }
  if(type==="tckn"){
      data = {
          type:type,
          tckn: tckn,
          password
      }
  }
  await axios
    .post(APIURL + "/auth/login", data)
    .then(res => {
      result = res;
    });
  return result;
}
async function logout(token) {
  let result = null;
  await axios
    .post(APIURL + "/auth/logout", null, {
        headers: {
            Bearer: token
        }
    })
    .then(res => {
      result = res;
    });
  return result;
}

async function register(
  name,
  surname,
  email,
  phone_number,
  identity_number,
  password,
  c_password,
  accept_terms,
  yearofbirth,
  country
) {
  let result = null;
  await axios
    .post(APIURL + "/auth/register", {
      first_name: name,
      last_name: surname,
      email: email,
      phone: phone_number,
      id_number: identity_number,
      password: password,
      c_password: c_password,
      birthday: yearofbirth,
      country: country,
      accept_terms: accept_terms
      //TODO ADD OTHER TEMRS TOO - MUST BE ADDED TO THE SERVICE
    })
    .then(res => {
      result = res;
    });
  return result;
}

async function initialiseChangePass(token, password, new_password, c_password) {
  let result = null;
  await axios
    .post(
      APIURL + "/user/change_password",
      { password: password, new_password:new_password, c_new_password:c_password },
      {
        headers: {
          Bearer: token
        }
      }
    )
    .then(res => {
      result = res;
    });
  return result;
}

async function smsConfirm(user_email, user_phone, user_country, user_tckn, type, code, login = false) {
  let result = null;
    let data;
    if(type==="email"){
        data = {
            user_email: user_email,
            code: code,
            type:type,
            login
        }
    }
    if(type==="phone"){
        data = {
            user_country: user_country,
            user_phone: user_phone,
            type:type,
            code: code,
            login
        }
    }
    if(type==="tckn"){
        data = {
            user_tckn: user_tckn,
            code: code,
            type:type,
            login
        }
    }

  await axios
    .post(APIURL + "/auth/sms_verify", data)
    .then(res => {
      result = res;
    });
  return result;
}

async function totpConfirm(user_email, phone, country, tckn, type, code, login = false) {
  let result = null;
    let data;
    if(type==="email"){
        data = {
            user_email: user_email,
            type:type,
            twoFACode: code,
            login
        }
    }
    if(type==="phone"){
        data = {
            user_country: country,
            user_phone: phone,
            type:type,
            twoFACode: code,
            login
        }
    }
    if(type==="tckn"){
        data = {
            user_tckn: tckn,
            type:type,
            twoFACode: code,
            login
        }
    }

  await axios
    .post(APIURL + "/security/2fa/check", data)
    .then(res => {
      result = res;
    });
  return result;
}

async function verifyRegisterPhone(user_email, smsCode, emailCode) {
  let result = null;
  await axios
    .post(APIURL + "/auth/verifyRegisterPhone", {
      user_email: user_email,
      code: smsCode
      // emailCode: emailCode
    })
    .then(res => {
      result = res;
    });
  return result;
}

async function getStatistics() {
  let result = null;
  await axios.get(APIURL + "/statistics").then(a => {
    result = a;
  });
  return result;
}

async function getCurrencies() {
  let result = null;
  await axios.get(APIURL + "/currencies").then(a => {
    result = a;
  });
  return result;
}

async function checkUser(token) {
  let result = null;
  await axios
    .post(APIURL + "/user/me", null, {
        headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" }
    })
    .then(a => {
      result = a;
    });
  return result;
}
async function getWallets(token) {
  let result = null;
  await axios
    .get(APIURL + "/user/wallet/list", {
      headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" }
    })
    .then(a => {
      result = a;
    });
  return result;
}

async function user_data(token) {
  if (typeof token != "undefined") {
    let result = null;
    await axios.post(APIURL + "/user_data", { headers: { Authorization: "Bearer " + token } }).then(a => {
      result = a;
    });
    return result;
  }
}


async function sendSms(token) {
  if (typeof token != "undefined") {
    let result = null;
    await axios.get(APIURL + "/security/2fa/switchTosms", { headers: { Authorization: "Bearer " + token } }).then(a => {
      result = a;
    });
    return result;
  }else{
      return 'Tekrar giriş yapmalısınız.';
  }
}
async function smsCofirm(token, code) {
  if (typeof token != "undefined") {
    let result = null;
    await axios.post(APIURL + "/security/2fa/checkSwitchSms", {
        code: code
    },{ headers: { Authorization: "Bearer " + token } }).then(a => {
      result = a;
    });
    return result;
  }else{
      return 'Tekrar giriş yapmalısınız.';
  }
}



async function getCommissions(token) {
  let result = null;
  await axios.get(APIURL + "/orders/commissions", {
      headers: { Authorization: "Bearer " + token }
  }).then(a => {
    result = a;
  });
  return result;
}

async function getOrders(code) {
  let result = null;
  await axios.get(APIURL + "/orders/" + code.replace("XBT/", "BTC_").replace("/", "_")).then(a => {
    result = a;
  });
  return result;
}

async function getMyOrders(code, token) {
  let result = null;
  await axios
    .get(APIURL + "/orders/" + code.replace("XBT/", "BTC_").replace("/", "_"), {
      headers: { Authorization: "Bearer " + token }
    })
    .then(a => {
      result = a;
    });
  return result;
}

async function getOrderBook(code, token, type) {
  let result = null;
  await axios.get(`${APIURL}/orderbook/${code}/${type}`, { headers: { Authorization: `Bearer ${token}` } }).then(a => {
    result = a;
  });
  return result;
}

async function makeOrder2(code, data, token) {
  let result = null;

  await axios
    .post(
      APIURL + "/orders/"+data.side,
      {
        summary: false,
        code,
        ...data
      },
      { headers: { Authorization: "Bearer " + token } }
    )
    .then(a => {
      result = a;
    });
  return result;
}
async function makeOrder(ordertype, pair, offer_price, volume, markettype, trigger, amount, token) {
  let result = null;

  if(ordertype==='buy'){
      if(markettype==='market'){
          volume = amount;
      }
      if(markettype==='limit'){
          volume = amount;
      }
      if(markettype==='stop'){
          volume = amount;
      }
  }
  if(ordertype==='sell'){
      if(markettype==='market'){
          volume = amount;
      }
      if(markettype==='limit'){
          volume = amount;
      }
      if(markettype==='stop'){
          volume = amount;
      }
  }

  await axios
    .post(
      APIURL + "/orders/"+ordertype,
      {
          type: markettype,
          pair: pair,
          offer_price:offer_price,
          volume:volume,
          amount:amount,
          trigger:trigger
      },
      { headers: { Authorization: "Bearer " + token } }
    )
    .then(a => {
      result = a;
    });
  return result;
}

async function cancelOrder(id, token) {
  let result = null;
  await axios.get(APIURL + "/orders/cancel?order_id=" + id, { headers: { Authorization: "Bearer " + token } }).then(a => {
    result = a;
  });
  return result;
}
async function deleteBankAccount(id, token) {
  let result = null;
  await axios.delete(APIURL + "/bank_accounts/" + id, { headers: { Authorization: "Bearer " + token } }).then(a => {
    result = a;
  });
  return result;
}

async function tl_action(token, type) {
  let result = null;
  await axios.get(APIURL + `/tl_action/${type}`, { headers: { Authorization: "Bearer " + token } }).then(a => {
  //await axios.get(APIURL + `/banks`, {}, { headers: { Authorization: "Bearer " + token } }).then(a => {
    result = a;
  });
  return result;
}

async function tl_actionCreateRequest(amount, bankaccount, token) {
  let result = null;
  await axios
    .post(
      APIURL + "/tl_action",
      {
        amount: amount,
        bankaccount: bankaccount
      },
      { headers: { Authorization: "Bearer " + token } }
    )
    .then(a => {
      result = a;
    });
  return result;
}

async function coin_action(code, token) {
  let result = null;
  await axios.get(APIURL + "/coin_actions/" + code, { headers: { Authorization: "Bearer " + token } }).then(a => {
    result = a;
  });
  return result;
}

async function create_wallet(code, token) {
  let result = null;
  await axios.post(APIURL + "/user/wallet/create", {
      currency: code
  },{ headers: { Authorization: "Bearer " + token }, "Content-Type": "application/json" }).then(a => {
    result = a;
  });
  return result;
}

async function wallet_transfer(to, currency, amount, otp, sms, email) {
  return api.post("/wallet/transfer", {
    address: to,
    currency,
    amount,
    verify_types: {
      sms: sms,
      email: email
    }
  });
}

async function tl_actionWithdrawCreateRequest(amount, bankaccount, token) {
  let result = null;
  await axios
    .post(
      APIURL + "/withdraw_tl?jsonResponse=true",
      {
        amount: amount,
        user_bank_account_id: bankaccount
      },
      { headers: { Authorization: "Bearer " + token } }
    )
    .then(a => {
      result = a;
    });
  return result;
}

async function tl_actionWithdrawCancelRequest(id, token) {
  let result = null;
  await axios
    .delete(APIURL + "/withdraw_tl/" + id + "?jsonResponse=true", { headers: { Authorization: "Bearer " + token } })
    .then(a => {
      result = a;
    });
  return result;
}

async function cancel_tl_deposit(id, token) {
  let result = null;
  await axios.delete(APIURL + "/cancel_tl_deposit/" + id, { headers: { Authorization: "Bearer " + token } }).then(a => {
    result = a;
  });
  return result;
}
async function cancel_tl_deposit_2(id, token) {
  let result = null;
  await axios
    .post(APIURL + "/withdraw-tl/cancel/" + id, null, { headers: { Authorization: "Bearer " + token } })
    .then(a => {
      result = a;
    });
  return result;
}

async function withdraw_gatewayRequest(code, token) {
  let result = null;
  await axios
    .get(
      APIURL + "/currency/withdraw/detail/"+code,
      { headers: { Authorization: "Bearer " + token } }
    )
    .then(a => {
      result = a;
    });
  return result;
}

async function withdraw_request(code, address, amount, gateway_code, token) {
  let result = null;
  await axios
    .post(
      APIURL + "/user/wallet/withdraw/"+code,
      {
        code: code,
        address: address,
        amount: amount,
        gateway_code: gateway_code,
        description: code+':'+gateway_code+':'+amount
      },
      { headers: { Authorization: "Bearer " + token } }
    )
    .then(a => {
      result = a;
    });
  return result;
}

async function change_password(old_password, password, password_confirmation, token, code) {
  let result = null;
  result = await axios.post(
    APIURL + "/auth/change_password",
    {
      old_password: old_password,
      password: password,
      password_confirmation: password_confirmation,
      confirmation_code: code
    },
    { headers: { Authorization: "Bearer " + token, Accept: "application/json" } }
  );

  return result;
}
async function change_password_confirm(code,token) {
  let result = null;
  result = await axios.post(
    APIURL + "/user/change_password/verify",
    {
      code: code
    },
    { headers: { Authorization: "Bearer " + token, Accept: "application/json" } }
  );

  return result;
}

async function getLoginLogs(token, page = 1, start_date, end_date) {
  // return axios.get(APIURL + "/user/lastLogin?" + qs.stringify({page}) + '&start_date='+start_date+'&end_date='+end_date, {
  return axios.post(APIURL + "/user/lastLogin?" + qs.stringify({page}),{
      start_date,
      end_date
  },{
    headers: { Authorization: "Bearer " + token }
  });
}

async function create_user_bank_account(token, iban, name) {
  let result = null;
  await axios
    .post(
      APIURL + "/user/wallet/recipient/add",
      {
        address: iban,
        account_name: name
      },
      { headers: { Authorization: "Bearer " + token } }
    )
    .then(a => {
      result = a;
    });
  return result;
}

async function getAccountInformation(token) {
  if (typeof token != "undefined") {
    let result = null;
    await axios
      .post(APIURL + "/user/me/account", null,{
        headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" }
      })
      .then(a => {
        result = a;
      });
    return result;
  }
}

async function getTransactions(token, pageNumber, setfilterPair, filterMarketType, setfilterStartDate, setfilterEndDate) {
  if (typeof token != "undefined") {
    let result = null;
      await axios
        .post(
          APIURL + "/user/me/transaction_history?page=" + pageNumber, {
                pair: setfilterPair,
                type: filterMarketType,
                start_date: setfilterStartDate,
                end_date: setfilterEndDate
            },
          { headers: { Authorization: "Bearer " + token } }
        )
        .then(a => {
          result = a;
        });
      return result;
  }
}

async function applyLevelForm(token, level_to, files) {
  let result = null;
  let fd = new FormData();

  fd.append("level_to", level_to);
  files.map(v => fd.append(v.key, v.value));
  await axios({
    method: "post",
    url: APIURL + "/user/store_user_documents?doc_type=GOV_ID",
    data: fd,
    headers: { Authorization: "Bearer " + token, "Content-Type": "multipart/form-data" }
  })
    .then(function (response) {
      result = response;
    })
    .catch(function (error) {
      result = error.response;
      //handle error
      console.log(error);
    });

  return result;
}

async function getNotification(token) {
    let result = null;
    await axios
        .get(APIURL + "/user/get_notifications", {
            headers: { Authorization: "Bearer " + token, "Content-Type": "multipart/form-data" }
        })
        .then(a => {
            result = a;
        });
    return result;
}
async function setNotification(token, code, value) {
    let result = null;
    let data = {
        code: code,
        value: value
    };
    await axios
        .post(APIURL + "/user/notifications", data, {
            headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" }
        })
        .then(a => {
            result = a;
        });
    return result;
}

async function emailVerify(token) {
  let result = null;
  await axios
    .get(APIURL + "/auth/confirm_email/"+token)
    .then(a => {
      result = a;
    });
  return result;
}
async function sendSmsCode(email) {
  let result = null;
  await axios
    .post(APIURL + "/auth/send_sms_code", {
      email: email
    })
    .then(a => {
      result = a;
    });
  return result;
}

async function resetPaswordEmailSend(email) {
  let result = null;
  await axios
    .post(APIURL + "/auth/password_reset", {
      email: email
    })
    .then(a => {
      result = a;
    });
  return result;
}
async function resetPaswordChange(token, password,c_new_password,phone_code) {
  let result = null;
  await axios
    .post(APIURL + "/auth/password_reset_verify", {
      new_password: password,
      c_new_password: c_new_password,
      phone_code: phone_code,
      email_code: token
    })
    .then(a => {
      result = a;
    });
  return result;
}

async function walletList(token) {
  let result = null;
  await axios.get(APIURL + "/user/wallet/list", { headers: { Authorization: "Bearer " + token } }).then(a => {
    result = a;
  });
  return result;
}
async function walletShow(code,token) {
  let result = null;
  await axios.get(APIURL + "/user/wallet/show?currency="+code,{ headers: { Authorization: "Bearer " + token } }).then(a => {
    result = a;
  });
  return result;
}

async function walletWithdrawRequests(token, currency) {
  let result = null;
  if (currency === undefined || currency === "") {
    await axios
      .get(
        APIURL + "/wallet/withdraw_requests",
        { currency: currency },
        { headers: { Authorization: "Bearer " + token } }
      )
      .then(a => {
        result = a;
      });
  } else {
    await axios
      .get(
        APIURL + "/wallet/withdraw/requests?currency=" + currency,
        {},
        { headers: { Authorization: "Bearer " + token } }
      )
      .then(a => {
        result = a;
      });
  }
  return result;
}

async function withdrawCancelRequest(token, id) {
  let result = null;
  await axios
    .post(APIURL + "/coin/withdraw/cancel/" + id, null, { headers: { Authorization: "Bearer " + token } })
    .then(a => {
      result = a;
    });
  return result;
}

async function totpSetup(token) {
  let result = null;
  await axios.get(APIURL + "/security/2fa/get/qr", { headers: { Authorization: "Bearer " + token } }).then(a => {
    result = a;
  });
  return result;
}

async function totpEnable(token, totp_token, totp_secret) {
    let result = null;
    await axios
        .post(
            APIURL + "/security/2fa/enable",
            {
                twoFACode: totp_token,
                twoFASecret: totp_secret,
            },
            {
                headers: { Authorization: "Bearer " + token }
            }
        )
        .then(a => {
            result = a;
        });
    return result;
}

async function totpDisable(token, totp_token) {
  let result = null;
  await axios
    .post(
      APIURL + "/security/2fa/disable",
      {
          twoFACode: totp_token
      },
      {
        headers: { Authorization: "Bearer " + token }
      }
    )
    .then(a => {
      result = a;
    });
  return result;
}

async function changePhishingCode(token, phishingCode) {
  let result = null;
  await axios
    .post(
      APIURL + "/security/phishing/set",
      {
        phishing_code: phishingCode
      },
      {
        headers: { Authorization: "Bearer " + token }
      }
    )
    .then(a => {
      result = a;
    });
  return result;
}
async function getPhishingCode(token) {
  let result = null;
  await axios
    .get(
      APIURL + "/security/phishing",
      {
        headers: { Authorization: "Bearer " + token }
      }
    )
    .then(a => {
      result = a;
    });
  return result;
}
async function depositSendBankInfo(bankId) {
  return api.post(`/bank_accounts/smsinfo/${bankId}`);
}

async function walletWithdrawVerifyMethod(method) {
  let result = null;
  await axios
    .post(APIURL + "/auth/verify_withdraw", {
      verify_type: method
    })
    .then(a => {
      result = a;
    });
  return result;
}

export {
  login,
  logout,
  smsConfirm,
  register,
  verifyRegisterPhone,
  getStatistics,
  checkUser,
  getOrders,
  makeOrder,
  makeOrder2,
  cancelOrder,
  getCommissions,
  getMyOrders,
  getOrderBook,
  tl_action,
  tl_actionCreateRequest,
  coin_action,
  create_wallet,
  wallet_transfer,
  walletShow,
  tl_actionWithdrawCreateRequest,
  tl_actionWithdrawCancelRequest,
  withdraw_request,
  change_password,
  change_password_confirm,
  cancel_tl_deposit,
  user_data,
  create_user_bank_account,
  getAccountInformation,
  getTransactions,
  applyLevelForm,
  getCurrencies,
  getLoginLogs,
  emailVerify,
  getWallets,
  walletList,
  totpSetup,
  totpEnable,
  totpDisable,
  totpConfirm,
  depositSendBankInfo,
  cancel_tl_deposit_2,
  resetPaswordEmailSend,
  resetPaswordChange,
  deleteBankAccount,
  walletWithdrawVerifyMethod,
  sendSmsCode,
  walletWithdrawRequests,
  withdrawCancelRequest,
  changePhishingCode,
  getPhishingCode,
  initialiseChangePass,
  withdraw_gatewayRequest,
  setNotification,
  getNotification,
  smsCofirm,
  sendSms
};
